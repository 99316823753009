import {focusElement} from '@wix/panda-js-utils'

export const focusFirstNewEvent = ({
  eventsCount,
  hasMore,
  pageLimit,
  compId,
  delay,
  innerDiv,
}: {
  eventsCount: number
  hasMore: boolean
  pageLimit: number
  compId: string
  delay?: number
  innerDiv?: boolean
}) => {
  if (eventsCount > pageLimit) {
    const index = hasMore ? eventsCount - pageLimit + 1 : eventsCount - (eventsCount % pageLimit) + 1
    const selector = `#${compId} li:nth-of-type(${index})${innerDiv ? ' > div' : ''}`

    if (delay) {
      setTimeout(() => focusElement({selector}), delay)
    } else {
      focusElement({selector})
    }
  }
}
