import {useStyles} from '@wix/tpa-settings/react'
import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import React, {useEffect, useReducer, useRef} from 'react'
import {DynamicStyle} from '../../../../../commons/components/dynamic-style'
import {isEditor} from '../../../../../commons/selectors/environment'
import stylesParams from '../../../stylesParams'
import {useWidgetState} from '../../hooks/state-provider'
import {useEventsList} from '../../hooks/use-events-list'
import {getCompId} from '../../selectors/component'
import {focusFirstNewEvent} from '../../utils/a11y'
import {LoadMoreButton} from '../load-more-button'
import {MainListTitle} from '../main-list-title'
import {Card} from './card'
import s from './cards.scss'
import {CardsProps} from '.'

export const Cards = ({t}: CardsProps) => {
  const container = useRef<HTMLDivElement>()
  const [, forceUpdate] = useReducer(x => x + 1, 0)
  const {events, hasMore, moreLoading, pageLimit} = useEventsList()
  const editor = useWidgetState(isEditor)
  const compId = useWidgetState(getCompId)

  const styles = useStyles()

  const cardsPerRow = styles.get(stylesParams.cardsPerRow)
  const eventsCount = events.length

  const columnCount = Math.min(cardsPerRow, eventsCount, pageLimit)

  useEffect(() => {
    const onResize = () => {
      forceUpdate()
    }
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  useEffect(() => {
    focusFirstNewEvent({eventsCount, pageLimit, hasMore, compId, delay: 500, innerDiv: true})
  }, [eventsCount])

  return (
    <div className={s.background}>
      {editor && (
        <DynamicStyle namespace={compId}>
          {`.${s.container} {
            --columns: ${columnCount};
          }`}
        </DynamicStyle>
      )}
      <div ref={container} className={s.container}>
        <MainListTitle />
        <ul className={s.cards} data-hook={DH.cards}>
          {events.map(event => (
            <Card event={event} t={t} key={event.id} />
          ))}
        </ul>
        <div className={s.loadMoreWrapper}>
          <LoadMoreButton hasMore={hasMore} moreLoading={moreLoading} />
        </div>
      </div>
    </div>
  )
}
